<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		
		<div class="section2">
			<div class="box">
				<div class="cate-title">报名项目</div>
				<div class="form-box">
					<div class="item">
						<div class="title">工种</div>
						<div class="right-box">
							<font>{{orderInfo.subject}}</font>
						</div>
					</div>
					<div class="item" v-if="orderInfo.dengji">
						<div class="title">技能等级</div>
						<div class="right-box">
							<font>{{orderInfo.dengji}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">领证类型</div>
						<div class="right-box">
							<font>{{orderInfo.type}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">报名途径</div>
						<div class="right-box">
							<font>{{orderInfo.basics}}</font>
						</div>
					</div>
					<div class="item" v-if="orderInfo.type!=='新领证'">
						<div class="title">证件有效期</div>
						<div class="right-box">
							<font>{{orderInfo.yxq}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">区域</div>
						<div class="right-box">
							<font>{{orderInfo.school}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">缴费方式</div>
						<div class="right-box">
							<font>{{orderInfo.pay_type}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">费用</div>
						<div class="right-box">
							<font color="#ff2e2e">¥{{orderInfo.money}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">创建时间</div>
						<div class="right-box">
							<font>{{orderInfo.addtime}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">订单编号</div>
						<div class="right-box">
							<font>{{orderInfo.order}}</font>
						</div>
					</div>
				</div>
			</div>
			
			
			<div class="box">
				<div class="cate-title">基本信息</div>
				<div class="form-box">
					<div class="item">
						<div class="title">姓名</div>
						<div class="right-box">
							<font>{{orderInfo.name}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">性别</div>
						<div class="right-box">
							<font>{{orderInfo.sex}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">身份证号</div>
						<div class="right-box">
							<font>{{orderInfo.cardid}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">学历</div>
						<div class="right-box">
							<font>{{orderInfo.edu}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">单位名称</div>
						<div class="right-box">
							<font>{{orderInfo.company}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">联系方式</div>
						<div class="right-box">
							<font>{{orderInfo.tel}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">微信号</div>
						<div class="right-box">
							<font>{{orderInfo.weixin}}</font>
						</div>
					</div>
					<div class="item">
						<div class="title">邮箱</div>
						<div class="right-box">
							<font>{{orderInfo.email}}</font>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '我的订单',
				orderInfo: '',
			}
		},
		computed: {
			uid() {
				 this.$store.commit('saveUid',this.$route.query.uid)
     			 return this.$route.query.uid;
			}
		},
		activated() {
			this.getUserInfo();
		},
		deactivated() {
			this.orderInfo = '';
		},
		methods: {
			// 获取订单详情
			async getOrderInfo() {
				var uid = this.uid;
				var order = this.$route.query.order;
				var data = {
					uid, order
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/order/order', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('订单详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var orderInfo = res.data;
					orderInfo['addtime'] = this.utils.js_date_time(orderInfo['addtime'],2);
					this.orderInfo = orderInfo;
				} else {
					this.orderInfo = '';
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return ;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getOrderInfo();
				}
			},
		},
	};
</script>

<style scoped>
	.section2{
		padding-top: 0.3rem;
		padding-bottom: 0.6rem;
	}
	.section2 .box{
		margin-top: 0.3rem;
	}
	.section2 .box:first-child{
		margin-top: 0;
	}
	.section2 .box .cate-title{
		height: 0.92rem;
		font-size: 0.32rem;
		color: #333333;
		display: flex;
		align-items: center;
		margin: 0 0.3rem;
		padding-left: 0.16rem;
		position: relative;
	}
	.section2 .box .cate-title::before{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.05rem;
		height: 0.26rem;
		background: #ff6000;
		left: 0;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.section2 .box .form-box{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section2 .box .form-box .item{
		padding: 0 0.3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1rem;
		border-top: 0.02rem solid #e1e1e1;
	}
	.section2 .box .form-box .item:first-child{
		border-top: none;
	}
	.section2 .box .form-box .item .title{
		font-size: 0.28rem;
		color: #333333;
	}
	.section2 .box .form-box .item .right-box{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 5rem;
	}
</style>